<template>
  <div class="panel-box">
    <div class="panel-box-title">
      <span class="text">{{ option.title }}</span>
      <span class="tip">{{ option.tip }}</span>
      <base-img-tag class="arrow" v-if="option.noBg" fileName="panelBox-bg-arrow.png" width="24px" height="8px"></base-img-tag>
      <base-img-tag v-else :fileName="option.longBg ? 'panelBox-bg-long-1.png' : 'panelBox-bg-1.png'"></base-img-tag>
      <base-img-tag class="title-right-bg" v-if="!option.noBg" fileName="panelBox-bg-2.png" width="240px" right="0"></base-img-tag>
    </div>
    <div class="panel-box-second-title" v-if="secondTitle && secondTitle.show">
      <div class="left">
        <base-img-tag class="icon" :fileName="secondTitle.iconPath" :bg="false" width="28px" height="28px"></base-img-tag>
        <span class="text">{{ secondTitle.text }}</span>
      </div>
      <div class="right">
        <span class="number">{{ secondTitle.total }}</span>
        <span class="unit">{{ secondTitle.unit }}</span>
      </div>
    </div>
    <div class="panel-box-content">
      <div class="panel-box-content-loading flex-center" v-show="option.loading">
        <base-img-tag fileName="loading.gif" width="40px" height="40px" :bg="false"></base-img-tag>
      </div>
      <div class="panel-box-content-empty flex-center" v-show="option.empty && !option.loading">
        <base-img-tag fileName="empty.png" width="261px" height="142px"></base-img-tag>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePanelBox',
  props: {
    option: {
      type: Object,
      default: () => ({
        title: '',
        tip: '',
        noBg: false,
        longBg: false,
        loading: false,
        empty: false,
        secondTitle: {
          show: false,
          iconPath: '',
          text: '',
          total: '',
          unit: ''
        }
      })
    }
  },
  data() {
    return {
    }
  },
  computed: {
    secondTitle() {
      return this.option.secondTitle
    }
  }
}
</script>

<style lang="stylus" scoped>
.panel-box{
  display flex
  flex-direction column
  height 100%
  .panel-box-title{
    position relative
    height 40px
    display flex
    align-items center
    .text{
      padding-left 32px
      font-size 16px
      font-weight bold
    }
    .tip{
      font-size 16px
      color #8FABBF
    }
  }
  .panel-box-second-title{
    position relative
    width 368px
    height 54px
    margin 16px 0 0 16px
    background linear-gradient(270deg, #C2D5E100 0%, #8FABBF26 100%)
    display flex
    justify-content space-between
    align-items center
    .left{
      display flex
      align-items center
      .icon{
        margin-left 14px
      }
      .text{
        margin-left 5px
      }
    }
    .right{
      display flex
      align-items center
      .number{
        font-size 18px
        font-weight bold
        color #BFEAFF
      }
      .unit{
        position relative
        top 2px
        margin-left 5px
        font-size 12px
        color #8FABBF
      }
    }
    &:before{
      content: ''
      position absolute
      top 0
      left 0
      display block
      width 2px
      height 54px
      background #BFEAFF
    }
  }
  .panel-box-content{
    position relative
    flex 1
    .panel-box-content-loading{
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      background-color rgba(3, 10, 10, 0.51)
      z-index 1
    }
    .panel-box-content-empty{
      position absolute
      top 0
      left 0
      right 0
      bottom 0
    }
  }
}
</style>
