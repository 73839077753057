export const panelModule = [{
  text: '整体统计',
  name: 'whole',
  value: 'whole'
}, {
  text: '成绩分析',
  name: 'score',
  value: 'score'
}, {
  text: '学科分析',
  name: 'subject',
  value: 'subject'
}, {
  text: '学生分析',
  name: 'student',
  value: 'student'
}]

export const timeScopeMap = new Map()
timeScopeMap.set('今日', '昨日')
timeScopeMap.set('本周', '上周')
timeScopeMap.set('本月', '上月')

export const lowGrade = [{
  text: '五年级',
  value: 5
}, {
  text: '六年级',
  value: 6
}]

export const middleGrade = [{
  text: '七年级',
  value: 7
}, {
  text: '八年级',
  value: 8
}, {
  text: '九年级',
  value: 9
}]

export const highGrade = [{
  text: '十年级',
  value: 10
}, {
  text: '十一年级',
  value: 11
}, {
  text: '十二年级',
  value: 12
}]

export const lowGradeSubject = [{
  text: '数学',
  value: '数学'
}]

export const middleGradeSubject = [{
  text: '数学',
  value: '数学'
}, {
  text: '物理',
  value: '物理'
}, {
  text: '化学',
  value: '化学'
}]

export const highGradeSubject = [{
  text: '数学',
  value: '数学'
}, {
  text: '物理',
  value: '物理'
}, {
  text: '化学',
  value: '化学'
}]

export const subjectColors = {
  '数学': '#DBB36E',
  '物理': '#3073B7',
  '化学': '#33CCCC',
  '语文': '#BFEAFF',
  '地理': '#5D33CC',
  '英语': '#C1DB6E',
  '科学': '#6DB347',
  '生物': '#CC8A33',
  '历史': '#C974AE',
  '道德与法治': '#EAD6B4'
}

export const colors = Object.values(subjectColors)
