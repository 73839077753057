<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import autofit from 'autofit.js'
export default {
  mounted() {
    autofit.init({
      dh: 1080,
      dw: 1920,
      el: "#app",
      resize: true
    }, false) // 可关闭控制台运行提示输出
  }
}
</script>

<style lang="stylus">
#nprogress .bar {
  background #33CCCC!important
  height 2px!important
}
</style>
