<template>
  <div class="scroll-box" :class="[hiddenScrollBar]" ref="scrollBox" @mouseover="onMouseover" @mouseleave="onMouseleave">
    <div class="scroll-main" ref="scrollMain">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseScrollBox',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // containerHeight: 0,
      // rowHeight: 0,
      // marginBottom: 0
      mouseover: false,
      timer: null
    }
  },
  computed: {
    hiddenScrollBar() {
      return this.mouseover ? '' : 'hidden-scroll-bar'
    }
  },
  methods: {
    backTop() {
      this.$refs.scrollBox.scrollTop = 0
    },
    onMouseover() {
      this.mouseover = true
      this.clearScroll()
    },
    onMouseleave() {
      this.mouseover = false
      this.startScroll()
    },
    isContentOverflow() {
      setTimeout(() => {
        if (this.$refs.scrollMain.clientHeight > this.$refs.scrollBox.clientHeight) {
          // this.resetScroll(0)
          this.startScroll()
        }
      }, 100) // 用宏任务来在页面渲染完之后再调用
    },
    startScroll() {
      const scrollMain = this.$refs.scrollMain
      if (!scrollMain) return
      if (this.disabled) return
      if (!this.rowHeight) {
        this.rowHeight = scrollMain.children[0].offsetHeight || 0
      }
      if (!this.marginBottom) {
        this.marginBottom = Math.floor((scrollMain.clientHeight - (scrollMain.children.length * scrollMain.children[0].offsetHeight)) / (scrollMain.children.length - 1))
      }
      const el = this.$refs.scrollBox
      if (!el) return
      const contentHeight = el.scrollHeight - el.clientHeight
      let moveHeight = this.rowHeight + this.marginBottom
      const move = () => {
        if (moveHeight > 0 && !this.mouseover) {
          moveHeight--
          el.scrollTop += 1
          requestAnimationFrame(move)
        } else {
          this.startScroll()
        }
      }
      this.timer = setTimeout(() => {
        if (el.scrollTop < contentHeight) {
          requestAnimationFrame(move)
        } else {
          this.backTop()
          this.startScroll()
        }
      }, 5000)
    },
    // startScroll() {
    //   try{
    //     if (!this.rowHeight) {
    //       this.rowHeight = this.$refs.scrollMain.children[0].offsetHeight
    //     }
    //     if (!this.containerHeight) {
    //       this.containerHeight = this.$refs.scrollMain.parentElement.clientHeight
    //     }
    //     if (!this.marginBottom) {
    //       this.marginBottom = Math.floor((this.$refs.scrollMain.clientHeight - (this.$refs.scrollMain.children.length * this.$refs.scrollMain.children[0].offsetHeight)) / (this.$refs.scrollMain.children.length - 1))
    //     }
    //   } catch (e) {
    //     console.dir(this.$refs.scrollMain)
    //   }
    //   const el = this.$refs.scrollMain
    //   this.timer = setTimeout(() => {
    //     if (!el) return // beforeDestroy的调用时机有时候会在定时器触发之后，这时候实例vm还在但是$refs的dom引用已经没了
    //     let top = el.translateY || 0
    //     if (!top) {
    //       el.style.transition = 'all .5s'
    //     }
    //     top += (this.rowHeight + this.marginBottom)
    //     el.style.transform = `translateY(-${top}px)`
    //     el.translateY = top
    //     if (top >= el.clientHeight - this.containerHeight) {
    //       this.resetScroll()
    //     } else {
    //       this.startScroll()
    //     }
    //   }, 5000)
    // },
    // resetScroll(wait = 5000) {
    //   this.clearScroll()
    //   const el = this.$refs.scrollMain
    //   if (!el) return // 关闭页面时有概率出现定时器先于beforeDestroy触发
    //   el.translateY = 0
    //   setTimeout(() => {
    //     el.style.transition = ''
    //     el.style.transform = `translateY(0)`
    //     this.startScroll()
    //   }, wait)
    // },
    clearScroll() {
      clearTimeout(this.timer)
    }
  },
  watch: {
    data() {
      this.clearScroll()
      if (this.data.length) this.isContentOverflow()
    }
  },
  beforeDestroy() {
    this.clearScroll()
    this.timer = null
  }
}
</script>

<style lang="stylus" scoped>
.scroll-box{
  height 100%
  overflow-y auto
  transition scroll-behavior 0.5s
  //overflow-y hidden
}
.hidden-scroll-bar{
  &::-webkit-scrollbar-thumb{
    background transparent
  }
}
</style>
