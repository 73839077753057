import { $request } from "@/api"
import { lowGrade, middleGrade, highGrade, lowGradeSubject, middleGradeSubject, highGradeSubject } from '@/static'

export default {
  namespaced: true,
  state: {
    gradeIndex: 0,
    gradeData: [],
    subjectIndex: 0,
    subjectData: [], // 部分
    subjectList: [] // 完整
  },
  mutations: {
    setGrade(state, index) {
      state.gradeIndex = index
    },
    setSubject(state, index) {
      state.subjectIndex = index
    },
    setSubjectList(state, list) {
      state.subjectList = list
    },
    setGradeAndSubjectData(state, educationStage) {
      state.gradeIndex = 0
      state.subjectIndex = 0
      switch (educationStage) {
        case '小学':
          state.gradeData = lowGrade
          state.subjectData = lowGradeSubject
          break
        case '初中':
          state.gradeData = middleGrade
          state.subjectData = middleGradeSubject
          break
        case '高中':
          state.gradeData = highGrade
          state.subjectData = highGradeSubject
          break
        default:
          state.gradeData = middleGrade
          state.subjectData = middleGradeSubject
      }
      state.subjectData = state.subjectData.map(i => {
        const current = state.subjectList.find(y => y.name === i.text)
        if (current) {
          return {
            text: current.name,
            value: current.id
          }
        }
      }).filter(i => i) // 拿科目id
    }
  },
  actions: {
    changeGrade({ commit, dispatch }, index) {
      commit('setGrade', index)
      dispatch('triggerEvent', `gradeChange`, { root: true })
      console.log('gradeChange')
    },
    changeSubject({ commit, dispatch }, index) {
      commit('setSubject', index)
      dispatch('triggerEvent', `subjectChange`, { root: true })
      console.log('subjectChange')
    },
    getSubjectList({commit}) {
      return $request('subjectList').then(res => {
        if (res.success) {
          commit('setSubjectList', res.data)
        }
      })
    },
    $request({ rootState, state }, key) {
      let data = {}
      if (typeof key === 'object') {
        data = key.data
        key = key.key
      }
      return new Promise((resolve, reject) => {
        const params = {
          cityId: rootState.user.eduBureauInfo.cityId,
          eduBureauId: rootState.user.eduBureauId,
          dateQueryType: rootState.eventCenter.timeScope,
          // dateQueryType: '5',
          stage: rootState.eventCenter.educationStage,
          gradeNumber: state.gradeData[state.gradeIndex].value,
          subject: state.subjectData[state.subjectIndex].text,
          subjectId: state.subjectData[state.subjectIndex].value,
          ...data
        }
        if (params.dateQueryType === '4') {
          params.beginQueryDate = rootState.user.semesterInfo.start
          params.endQueryDate = rootState.user.semesterInfo.end
        }
        $request(key, params).then(res => {
          res ? resolve(res) : resolve({})
        }).catch(e => resolve({}))
      })
    }
  }
}
