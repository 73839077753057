import CryptoJS from 'crypto-js'
const key = 'msy-data'

export function setEducationStageIndex(index) {
  sessionStorage.setItem('educationStageIndex', index)
}

export function getEducationStageIndex() {
  return Number(sessionStorage.getItem('educationStageIndex'))
}

// -----------------------------------------------------

export function setTimeScopeIndex(scope) {
  sessionStorage.setItem('timeScopeIndex', scope)
}

export function getTimeScopeIndex() {
  return Number(sessionStorage.getItem('timeScopeIndex'))
}

// -----------------------------------------------------

export function setAccessToken(token) {
  localStorage.setItem('accessToken', token)
}

export function getAccessToken() {
  return localStorage.getItem('accessToken')
}

export function delAccessToken() {
  return localStorage.removeItem('accessToken')
}

// -----------------------------------------------------

export function setRefreshToken(token) {
  localStorage.setItem('refreshToken', token)
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken')
}

export function delRefreshToken() {
  return localStorage.removeItem('refreshToken')
}

// -----------------------------------------------------

export function setEduBureauId(id) {
  localStorage.setItem('eduBureauId', id)
}

export function getEduBureauId() {
  return localStorage.getItem('eduBureauId')
}

export function delEduBureauId() {
  return localStorage.removeItem('eduBureauId')
}

// -----------------------------------------------------

export function setLoginInfo(name, pwd, remember) {
  localStorage.setItem('loginInfo', JSON.stringify({
    name,
    pwd: CryptoJS.AES.encrypt(pwd, key).toString(),
    remember
  }))
}

export function getLoginInfo() {
  const info = JSON.parse(localStorage.getItem('loginInfo'))
  if (info) info.pwd = CryptoJS.AES.decrypt(info.pwd, key).toString(CryptoJS.enc.Utf8)
  return info
}

export function delLoginInfo() {
  return localStorage.removeItem('loginInfo')
}

// -----------------------------------------------------

export function setDataStage(stage) { // 1 往期 2 本学期
  localStorage.setItem('dataStage', stage)
}

export function getDataStage() {
  return Number(localStorage.getItem('dataStage'))
}

// -----------------------------------------------------

export function setDataStageDetail(detail) { // 1 往期 2 本学期
  localStorage.setItem('dataStageDetail', JSON.stringify(detail))
}

export function getDataStageDetail() {
  return JSON.parse(localStorage.getItem('dataStageDetail')) || {}
}

export function delDataStageDetail() {
  return localStorage.removeItem('dataStageDetail')
}
