<template>
  <img class="img-tag" :class="{ bg }" :style="{ top, left, right, bottom, width, height }" :src="url">
</template>

<script>
export default {
  name: 'BaseImgTag',
  props: {
    bg: {
      type: Boolean,
      default: true
    },
    top: {
      type: String,
      default: 'auto'
    },
    left: {
      type: String,
      default: 'auto'
    },
    right: {
      type: String,
      default: 'auto'
    },
    bottom: {
      type: String,
      default: 'auto'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    fileName: String
  },
  data() {
    return {
      url: this.$utils.getImgSrc(this.fileName)
    }
  }
}
</script>

<style lang="stylus" scoped>
.img-tag{
  position relative
  display block
}
.bg{
  position absolute
  z-index -1
}
</style>
