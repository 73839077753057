import { $request } from "@/api"

export default {
  state: {
    activePanelName: '',
    center: {},
    timeScopeCn: '',
    timeScope: '',
    educationStage: ''
  },
  mutations: {
    setEducationStage(state, value) {
      state.educationStage = value
    },
    setTimeScope(state, { text, value }) {
      state.timeScopeCn = text
      state.timeScope = value
    },
    setActivePanelName(state, panelName) {
      state.activePanelName = panelName
    },
    registerEvent(state, event) {
      if (!state.center[event]) state.center[event] = []
    },
    deleteEvent(state, event) {
      if (state.center[event] && state.center[event].length) state.center[event] = []
    },
    clearEvent(state) {
      Object.keys(state.center).forEach(i => {
        state.center[i] = []
      })
    },
    addEventCallBackFunc(state, { event, func }) {
      if (state.center[event]) {
        state.center[event].push(func)
      }
    }
  },
  actions: {
    triggerEvent({ state }, event) {
      if (state.center[event]) {
        state.center[event].forEach(func => func())
      }
    },
    changeEducationStage({ state, commit, dispatch }, params) {
      commit('setEducationStage', params)
      commit('gradeAndSubject/setGradeAndSubjectData', params)
      dispatch('triggerEvent', `educationStageChange`)
      console.log('educationStageChange')
    },
    changeTimeScope({ commit, dispatch }, params) {
      commit('setTimeScope', params)
      dispatch('triggerEvent', `timeScopeChange`)
      console.log('timeScopeChange')
    },
    $request({ rootState, state }, key) {
      let data = {}
      if (typeof key === 'object') {
        data = key.data
        key = key.key
      }
      return new Promise((resolve, reject) => {
        const params = {
          cityId: rootState.user.eduBureauInfo.cityId,
          eduBureauId: rootState.user.eduBureauId,
          stage: state.educationStage,
          dateQueryType: state.timeScope,
          // dateQueryType: '5',
          ...data
        }
        if (params.dateQueryType === '4') {
          params.beginQueryDate = rootState.user.semesterInfo.start
          params.endQueryDate = rootState.user.semesterInfo.end
        }
        $request(key, params).then(res => {
          res ? resolve(res) : resolve({})
        }).catch(e => resolve({}))
      })
    }
  }
}
