import request from '@/utils/request'
import axios from 'axios'
import qs from 'qs'

const api = {
  login: ['/auth/v2/authentication', 'post', 'urlencoded'],
  smsCodeLogin: ['/auth/teacher/phone/authentication', 'post'],
  getUserInfo: ['/auth/MyPrivileges', 'get'],
  getAdminEduList: ['/user/resource', 'get'],
  getSemesterList: ['/data-center/big-screen/city-semester/list', 'get'],
  refreshToken: ['/auth/refresh-token', 'post', 'urlencoded'],
  logout: ['/auth/logout', 'post', 'urlencoded'],
  getWXLoginUrl: ['/auth/3rd-party/wechat-qrcode-url', 'get'],
  getWXAuthentication: ['/auth/3rd-party/wechat/authentication', 'post'],
  getSmsCode: ['/auth/teacher/phone', 'post'],
  bindPhoneAnd3rdParty: ['/auth/3rd-party/phone/bind', 'post'],
  eduBureauDetail: ['/school/education', 'get'],
  userManageEduBureauList: ['/user/resource', 'get', { resourceType: 2 }],
  subjectList: ['/misc/subject/list', 'get'],
  wholeHomeworkCorrectNum: ['/data-center/big-screen/task/review', 'get'],
  wholeMicroCourseNum: ['/data-center/big-screen/media/study', 'get'],
  wholeWrongTotal: ['/data-center/big-screen/student/incorrect-topic', 'get'],
  wholeUsageRate: ['/data-center/big-screen/school/review-rate/list', 'get'],
  wholeTeacherActiveRank: ['/data-center/big-screen/school/teacher-review', 'get'],
  wholeClassActiveRank: ['/data-center/big-screen/school/class-review', 'get'],
  wholePersonalExercise: ['/data-center/big-screen/personal-practice', 'get'],
  wholeSchoolStatistics: ['/data-center/big-screen/district/school/profile', 'get'],
  wholeSchoolList: ['/data-center/big-screen/school/list', 'get'],
  wholeSchoolDetail: ['/data-center/big-screen/school/review', 'get'],
  wholeSchoolDetailList: ['/data-center/big-screen/school/review/list', 'get'],
  scoreSchoolHomeworkTotal: ['/data-center/big-screen/school/task/count', 'get'],
  scoreSchoolHomeworkCorrectRate: ['/data-center/big-screen/school/task/correct-rate', 'get'],
  scoreSchoolChapterScoreRate: ['/data-center/big-screen/task/chapter/school/score-rate', 'get'],
  scoreChapterCorrectRate: ['/data-center/big-screen/task/knowledge/correct-rate', 'get'],
  subjectRepeatWrongRateByChapter: ['/data-center/big-screen/high/chapter', 'get'],
  subjectRepeatWrongRateBySection: ['/data-center/big-screen/high/section', 'get'],
  subjectRepeatWrongRateByKnowledge: ['/data-center/big-screen/high/knowledge', 'get'],
  subjectCorrectRate: ['/data-center/big-screen/subject/correct-rate', 'get'],
  subjectDifficultAndWrongRate: ['/data-center/big-screen/difficulty/rate', 'get'],
  subjectStudyProcess: ['/data-center/big-screen/school/study/progress', 'get'],
  subjectQuestionType: ['/data-center/big-screen/question-type/rate', 'get'],
  subjectSkillAnalysis: ['/data-center/big-screen/subject/ability', 'get'],
  studentLevel: ['/data-center/big-screen/student/level', 'get'],
  studentStudyPeriodByDay: ['/data-center/big-screen/study/day', 'get'],
  studentStudyPeriodByHour: ['/data-center/big-screen/study/hour', 'get'],
  studentGrade: ['/data-center/big-screen/grade/profile', 'get'],
  studentLevelBySchool: ['/data-center/big-screen/school/student-level/list', 'get'],
  studentTotal: ['/data-center/big-screen/student/count', 'get'],
  studentSexRatio: ['/data-center/big-screen/grade/student', 'get'],
  studentTeacherTotal: ['/data-center/big-screen/subject/teacher', 'get'],
  studentGetSchoolCourseDetail: ['/data-center/big-screen/school/media-custom-asmt/count', 'get']
}

export function $request(apiKey, data = {}, config = {}) {
  let target = api[apiKey]
  let dataType = target[1] === 'get' ? 'params' : 'data'
  if (target[2]) {
    if (target[2] === 'urlencoded') {
      data = qs.stringify(data)
    } else if (target[2] instanceof Object) {
      data = { ...target[2], ...data }
    }
  }
  return request({
    url: config.dynamicUrl ? target[0] + config.dynamicUrl : target[0],
    method: target[1],
    [dataType]: data,
    ...config
  })
}

export function getMapJson(id) {
  return axios.get(`https://pen.zwres.com/maps/${id}.geojson`).then(res => {
    if (res.data) {
      return Promise.resolve(res.data)
    } else {
      return Promise.resolve('')
    }
  })
}
