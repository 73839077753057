import { colors, subjectColors } from '@/static'

let windowInnerWidth = ''

function getImgSrc(fileName) {
  if (!fileName) {
    return ''
  }
  const small = require(`@/assets/1x/${fileName}`)
  const big =  require(`@/assets/2x/${fileName}`)
  if (!windowInnerWidth) {
    windowInnerWidth = window.innerWidth
  }
  if (windowInnerWidth <= 1920) {
    return small
  } else {
    return big
  }
}

function color16ToRgb(str) {
  let reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
  if (!reg.test(str)) {
    return
  }
  let newStr = (str.toLowerCase()).replace(/\#/g, '')
  let len = newStr.length
  if (len === 3) {
    let t = ''
    for (let i = 0; i < len; i++) {
      t += newStr.slice(i, i + 1).concat(newStr.slice(i, i + 1))
    }
    newStr = t
  }
  let arr = []
  for (let i = 0; i < 6; i = i + 2) {
    let s = newStr.slice(i, i + 2)
    arr.push(parseInt("0x" + s))
  }
  return arr.join(",")
}

function delProperty(target = {}, ref = {}) {
  const refKeys = Array.isArray(ref) ? ref : Object.keys(ref)
  refKeys.forEach(name => {
    if (name in target) {
      delete target[name]
    }
  })
}

function addProperty(target = {}, ref = {}) {
  const refKeys = Object.keys(ref)
  if (refKeys.length) {
    refKeys.forEach(name => {
      target[name] = ref[name]
    })
  }
}

function formatterRate(number, digits) {
  return Number((number * 100).toFixed(digits))
}

function mergeObject(a,b) {
  const bKeys = Object.keys(b)
  bKeys.forEach(i => {
    if (a[i]) {
      if (a[i] instanceof Array) {
        a[i] = [...a[i], ...(b[i] || [])]
      } else {
        a[i] = {...a[i], ...(b[i] || {})}
      }
    } else {
      a[i] = b[i]
    }
  })
  return a
}

const strategies = {
  isEmpty(value) {
    if (value === '') {
      return `不能为空`
    }
  },
  minLength(value, length) {
    if (value.length < length) {
      return `不能小于${length}位`
    }
  }
}

export function validator(value, name, strategy = []) {
  for (let i = 0; i < strategy.length; i++) {
    const result = strategies[strategy[i][0]](value, ...strategy[i].slice(1))
    if (result) return name + result
  }
  return ''
}

export const getColor = function(index, myColors = colors) {
  if (index < 0) return
  if (index > myColors.length - 1) {
    return myColors[index % myColors.length]
  } else {
    return myColors[index]
  }
}

function getSubjectColor(name) {
  return subjectColors[name] || '#f5f5f7'
}

function getUnicodePoint(str) {
  return str ? str.split('').reduce((sum, char) => sum + char.charCodeAt(), 0) : ''
}

function getSubjectTextConfig(subjectList = []) {
  let tmp = {}
  subjectList.forEach(i => {
    tmp[`${getUnicodePoint(i)}`] = {
      fontSize: 14,
      color: getSubjectColor(i)
    }
  })
  return tmp
}

function shortNum(num) {
   if (num < 100000) {
     return num
   } else {
     return `${parseInt(num / 10000)}万`
   }
}

function normalizeNum(num) {
  let pattern = /(-?\d+)(\d{3})/
  num = num.toString()
  while (pattern.test(num)) {
    num = num.replace(pattern, "$1,$2")
  }
  return num
}

export default {
  getColor,
  getSubjectColor,
  getUnicodePoint,
  getSubjectTextConfig,
  getImgSrc,
  shortNum,
  normalizeNum,
  color16ToRgb,
  addProperty,
  delProperty,
  formatterRate,
  mergeObject,
  validator
}
