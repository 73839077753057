import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import { $request } from '@/api'
import BasePanelBox from '@/components/BasePanelBox'
import BaseScrollBox from '@/components/BaseScrollBox'
import BaseImgTag from '@/components/BaseImgTag'
import utils from '@/utils'
import './permission'

import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.styl'

Vue.component('base-panel-box', BasePanelBox)
Vue.component('base-scroll-box', BaseScrollBox)
Vue.component('base-img-tag', BaseImgTag)
Vue.prototype.$request = $request
Vue.prototype.$utils = utils

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
