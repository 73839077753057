import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { Message } from 'element-ui'
const routesWhiteList = ['/login', '/3rd-login', '/register', '/404', '/401']

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async(to, from, next) => {
  NProgress.start()
  if (store.state.user.accessToken) {
    if (to.path === '/login') {
      next('/')
      NProgress.done()
    } else {
      if (store.state.user.userInfo) {
        next()
      } else {
        try {
          await store.dispatch('getUserInfo')
          next()
        } catch (err) {
          try {
            await store.dispatch('refreshToken')
            next()
          } catch (err) {
            await store.dispatch('logoutPassive') // 清空vuex里面的token和storage里面的
            Message({
              message: '请重新登录',
              type: 'error',
              duration: 5 * 1000
            })
            next('/')
            NProgress.done()
          }
        }
      }
    }
  } else {
    if (routesWhiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
