import Vue from 'vue'
import Vuex from 'vuex'
import eventCenter from '@/store/eventCenter'
import user from '@/store/user'
import gradeAndSubject from '@/store/gradeAndSubject'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    eventCenter,
    user,
    gradeAndSubject
  }
})
