import axios from 'axios'
import store from '../store'
import router from '../router'
import { Message } from 'element-ui'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + '/api',
  // baseURL: '/api',
  timeout: 50000
})

// request interceptor
request.interceptors.request.use(config => {
  config.headers['Authorization'] = config.Authorization || `Bearer ${store.state.user.accessToken}`
  return config
}, error => {
  // console.log(error)
  return Promise.reject(error)
})

// response interceptor
request.interceptors.response.use(response => {
  return response.data
}, async error => {
  // console.log(error)
  const data = error.response.data
  const code = data.error.code
  if (code) {
    const userInfo = store.state.user.userInfo
    if (code === 10005) {
      Message.error('长时间未操作，请重新登录')
      router.replace('/login')
    } else if (code === 10007) { // 刷新token过期/失效
      if (userInfo) { // 判断用户信息是否存在是因为这个进入这个报错有两种情况，一种是已经获取到用户信息但是token过期了，另一种是在获取用户信息的过程中token过期/无效了
        await store.dispatch('logoutPassive')
        Message.error('请重新登录')
        router.push('/login')
      }
    } else if (code === 10008) { // 用户token过期/失效
      if (userInfo) { // 判断用户信息是否存在是因为这个进入这个报错有两种情况，一种是已经获取到用户信息但是token过期了，另一种是在获取用户信息的过程中token过期/无效了
        store.commit('setUserInfo', null)
        router.go(0) // 重新走permission的流程
      }
    } else if (code === 11002) {
      Message.error('用户名或密码不正确')
    } else {
      Message.error(data.error.message)
    }
  }
  return Promise.reject(error)
})

export default request
